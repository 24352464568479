import { addYears, getDay, isAfter, isBefore, setHours } from "date-fns";
import { createContext, useCallback, useContext, useState } from "react";

import type { Weekdays } from "../types";
import { WEEKDAYS } from "../utils/constants";
import {
  getAddressCoverage,
  getAddressCoverageByDate,
  validateAddressInterruptions,
} from "../api/address.api";

type Slot = {
  id: string;
  start: string;
  end: string;
  enabledDays: Weekdays[];
  startHour: number;
  endHour: number;
  label: string;
};

type Interruption = {
  id: string;
  startsAt: Date;
  endsAt: Date;
};

type Coverage = {
  isCovered: boolean;
  slots: Slot[];
  filteredSlots?: Slot[];
  interruptions: Interruption[];
  availableDays: Weekdays[];
  postalCode: string;
};

export interface AddressCoverageContextType {
  clearCoverage: () => void;
  setPostalCode: (postalCode: string) => void;
  fetchCoverageByDate: (postalCode: string, date: Date) => Promise<void>;
  fetchCoverage: (postalCode: string) => Promise<void>;
  loading: boolean;
  error: string | null;
  isCovered: boolean;
  getSlotsForDate: (date: Date) => Slot[];
  minDate: Date;
  maxDate: Date;
  postalCode?: string;
  getFilteredSlots: () => Slot[];
  displayUncoveredAddress: boolean;
  setAddressState: (state: string) => void;
  addressLoading: boolean;
  fullTextAddress: string | null;
  setFullTextAddress: (fullTextAddress: string) => void;
  addressPlace: google.maps.places.PlaceResult | null;
  setAddressPlace: (address: google.maps.places.PlaceResult) => void;
  externalPlace: google.maps.places.PlaceResult | null;
  setExternalPlace: (address: google.maps.places.PlaceResult) => void;
  validateInterruption: (date: Date, address: any) => Promise<any>;
}

const AddressCoverageContext = createContext<AddressCoverageContextType | null>(
  null
);

export const AddressCoverageProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [coverage, setCoverage] = useState<Coverage | null>(null);
  const [displayUncoveredAddress, setDisplayUncoveredAddress] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const minDate = new Date();
  const maxDate = addYears(minDate, 1);
  const [addressLoading, setAddressLoading] = useState(false);
  const [fullTextAddress, setFullTextAddress] = useState<string | null>(null);
  const [addressPlace, setAddressPlace] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [externalPlace, setExternalPlace] =
    useState<google.maps.places.PlaceResult | null>(null);
  const getFilteredSlots = useCallback(() => {
    return coverage?.filteredSlots || coverage?.slots || [];
  }, [coverage]);

  const setPostalCode = useCallback((postalCode: string) => {
    setCoverage((prevState: any) => {
      return {
        ...prevState,
        postalCode,
      };
    });
  }, []);

  const fetchCoverageByDate = useCallback(
    async (postalCode: string, date: Date) => {
      setError(null);
      try {
        const response = await getAddressCoverageByDate(postalCode, date);

        setDisplayUncoveredAddress(!response.isCovered);

        setCoverage((prevState: Coverage | null) => {
          return {
            ...prevState,
            postalCode: prevState?.postalCode || "",
            isCovered: response.isCovered,
            slots: prevState?.slots || [],
            filteredSlots: response.slots.map((slot) => {
              const startHour = parseInt(slot.start.split(":")[0]);
              const endHour = parseInt(slot.end.split(":")[0]);
              return {
                ...slot,
                startHour,
                endHour,
                label: `${startHour}-${endHour}h`,
              };
            }),
            interruptions: response.interruptions,
            availableDays: response.slots.reduce<Weekdays[]>((acc, slot) => {
              return [...acc, ...slot.enabledDays];
            }, []),
          };
        });
      } catch (error) {
        console.error(error);
        setError("Une erreur est survenue, veuillez réessayer");
        setCoverage(null);
      }
    },
    []
  );

  const validateInterruption = useCallback(async (date: Date, address: any) => {
    const response = await validateAddressInterruptions(date, address);
    return response;
  }, []);

  const fetchCoverage = useCallback(async (postalCode: string) => {
    setError(null);
    setAddressLoading(true);
    try {
      const response = await getAddressCoverage(postalCode);

      setDisplayUncoveredAddress(!response.isCovered);
      setCoverage((prevState: Coverage | null) => {
        return {
          ...prevState,
          postalCode: prevState?.postalCode || "",
          isCovered: response.isCovered,
          slots: response.slots.map((slot) => {
            const startHour = parseInt(slot.start.split(":")[0]);
            const endHour = parseInt(slot.end.split(":")[0]);
            return {
              ...slot,
              startHour,
              endHour,
              label: `${startHour}-${endHour}h`,
            };
          }),
          interruptions: response.interruptions,
          availableDays: response.slots.reduce<Weekdays[]>((acc, slot) => {
            return [...acc, ...slot.enabledDays];
          }, []),
        };
      });
    } catch (error) {
      console.error(error);
      setError("Une erreur est survenue, veuillez réessayer");
      setCoverage(null);
    } finally {
      setAddressLoading(false);
    }
  }, []);

  const clearCoverage = useCallback(() => {
    setCoverage(null);
  }, []);

  const getSlotsForDate = useCallback(
    (date: Date): Slot[] => {
      if (!coverage) {
        return [];
      }

      const currentDay = WEEKDAYS[getDay(date)];

      const slots = coverage.slots
        .filter((s) => {
          const isDayEnabled = s.enabledDays.includes(currentDay);
          return isDayEnabled;
        })
        .filter((s) => {
          const isNotInterrupted = !coverage.interruptions.some(
            (interruption) => {
              const interruptionStartsAt = new Date(interruption.startsAt);
              const interruptionEndsAt = new Date(interruption.endsAt);
              const slotStartsAt = setHours(date, s.startHour);
              const slotEndsAt = setHours(date, s.endHour);
              return (
                isBefore(slotStartsAt, interruptionEndsAt) &&
                isAfter(slotEndsAt, interruptionStartsAt)
              );
            }
          );
          return isNotInterrupted;
        });

      return slots;
    },
    [coverage]
  );

  const setAddressState = useCallback((state: string) => {
    switch (state) {
      case "ERROR":
        setCoverage((prev) => {
          if (!prev) return null;
          return {
            ...prev,
            isCovered: false,
          };
        });
        break;
      default:
        break;
    }
  }, []);

  const initialState = {
    clearCoverage,
    setPostalCode,
    getFilteredSlots,
    fetchCoverage,
    fetchCoverageByDate,
    loading: !coverage && !error,
    error,
    isCovered: coverage?.isCovered || false,
    displayUncoveredAddress,
    setAddressState,
    getSlotsForDate,
    minDate,
    maxDate,
    postalCode: coverage?.postalCode || "",
    addressLoading,
    fullTextAddress,
    setFullTextAddress,
    validateInterruption,
    addressPlace,
    setAddressPlace,
    externalPlace,
    setExternalPlace,
  };

  return (
    <AddressCoverageContext.Provider value={initialState}>
      {children}
    </AddressCoverageContext.Provider>
  );
};

export const useAddressCoverage = () => {
  const context = useContext(AddressCoverageContext);
  if (!context) {
    throw new Error("useAddressCoverage was used outside of its Provider");
  }
  return context;
};
